import http from "../http-common";
import authHeader from "./auth-header";

const getDashboardList = () => http.get("/GetRecordCounts", { headers: authHeader()});
const getListOfTop = () => http.get("/admin/recentusers", { headers: authHeader()});

const DashboardServices = {
  getDashboardList,
  getListOfTop,

};
export default DashboardServices;
