import http from '../http-common';
// ---SMTP-API--start---
const get = (UserGuid) =>
  http.get(`GetSMTP_Info`, {
    params: {
      UserGuid,
    },
  });
const update = (data) => http.put(`UpdateSMTP_Info`, data);
// ---SMTP-API--start---

// ---Application-API--start---
const getApplicationSettings = (UserGuid) => {
  return http.get('/GetApplicationSetting_Info', {
    params: {
      UserGuid, // Pass the UserGuid as a query parameter
    },
  });
};
// ---Application-API-end---//

// ---Company--API--start---//
const getCompany = (UserGuid) => {
  return http.get(`/GetCompanyInfo`, {
    params: {
      UserGuid, // Pass the UserGuid as a query parameter
    },
  });
};

const updateCompany = (data) => http.put(`/UpdateCompanyInfo`, data);
// ---Company-API--end---//
// ---Developer-API--start---//
const getDeveloper = (UserGuid) => {
  return http.get(`/GetDeveloperSetting_Info`, {
    params: {
      UserGuid,
    },
  });
};
const updateDeveloper = (data) => http.put(`/UpdateDeveloperSetting_Info`, data);
// ---Developer--API--end---
// ---Notification--API--start---//
const getNotification = () => http.get(`GetFCMSetting_Info?Id=1`);
const updateNotification = (data) => {
  return http.put(`UpdateFCMSetting_Info`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// ---Notification-API--end---//
// ---Sendnotification-API- start---//
// ---Sendnotification-API--end---//

// ---anjani---

// ---anani-end--
const getCoins = () => http.get(`/CoinManagement/getplan`);

const getNotificationUser = () => http.get(`/GetUserWithDeviceToken`);

const getPayment = () => http.get(`/Payment/get`);

const create = (data) => {
  console.log(data);
  return http.post('/users', data);
};

const updateCoins = (data) => http.post(`/CoinManagement/update`, data);
const updatePayment = (data) => http.put(`/Payment/update`, data);
const updateApplicationSettings = (data) => http.put(`/UpdateApplicationSetting_Info`, data);
const remove = (id) => http.post(`/users/delete/${id}`);

// const sendTestNotification = (userguid, deviceTokens) => {
//  return http.post(`/TestFCMNotification`, {
//     params: {
//       userguid,
//       deviceTokens,
//     },
//   });
// };
const sendTestNotification = (userguid, deviceTokens, id) => {
  return http.post(`/TestFCMNotification?userguid=${userguid}&deviceTokens=${deviceTokens}&id=1`);
};
const sendSMTPNotification = (Email, UserGuid) => {
  return http.post(`/SMTP_SendinfoForTest?Email=${encodeURIComponent(Email)}&UserGuid=${UserGuid}`);
};

const AppSettings = {
  get,
  create,
  update,
  remove,
  getCompany,
  updateCompany,
  getCoins,
  updateCoins,
  getNotification,
  updateNotification,
  sendTestNotification,
  getDeveloper,
  updateDeveloper,
  sendSMTPNotification,
  getPayment,
  updatePayment,
  getApplicationSettings,
  updateApplicationSettings,
  getNotificationUser,
};

export default AppSettings;
