import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import { Card, Stack, Container, Typography, TextField, Box, Button, CircularProgress } from '@mui/material';
import { CirclesWithBar } from 'react-loader-spinner';
import './customStyles.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import Autocomplete from '@mui/material/Autocomplete';
import { useParams } from 'react-router-dom';

import AppSettings from '../services/AppSettings';

export default function NotificationSettings() {
  const initialUserService = {
    id: '',
    fiberBaseId: '',
    deviceToken: '',
    fullName: '',
  };
  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [userGuid, setUserGuid] = useState('');
  // const [sortedUser, setSortedUser] = useState([]); // List of users for Autocomplete
  const [currentuserdevice, setCurrentuserdevice] = useState({ deviceToken: '' });

  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const getUser = () => {
    setCustomLoader(true);
    AppSettings.getNotification()
      .then((response) => {
        setCurrentUser(response.data);
        setCustomLoader(false);
        console.log(response.data);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const updateUser = () => {
    if (!file) {
      toast.error('Please select a file to upload!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('UploadedFile', file);
    formData.append('id', 1); // Append file to form data
    // formData.append('fiberBaseId', fiberBaseId);

    console.log(JSON.stringify(formData));

    AppSettings.updateNotification(formData)
      .then((response) => {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  // const handleInputChanges = (event) => {
  //   const { name, value } = event.target;
  // };

  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setCurrentUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUserSelect = (event, selectedUser) => {
    if (selectedUser) {
      console.log('selectedUser', selectedUser);
      // Assuming selectedUser contains a 'deviceToken' field or you need to fetch it
      setCurrentuserdevice({ deviceToken: selectedUser.deviceToken || '' });

      // If you need to fetch the user's device token separately:
      // fetchDeviceToken(selectedUser.id);
    } else {
      setCurrentuserdevice({ deviceToken: '' });
    }
  };

  // ----send-notification-API
  const getnotication = () => {
    setCustomLoader(true);
    AppSettings.getNotificationUser()
      .then((response) => {
        console.log('notification', response);
        setCurrentUser(response.data);
        setCustomLoader(false);
        console.log(response.data);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    getnotication();
  }, []);

  const noticationSend = () => {
    const userguid = localStorage.getItem('id');
    const deviceTokens = localStorage.getItem('token');

    // Replace with your actual local storage key

    setLoadingSend(true);
    AppSettings.sendTestNotification(userguid, deviceTokens)
      .then((response) => {
        toast.success('Notification send successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(response.data.data);
        setLoadingSend(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoadingSend(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Notification Settings | Carib Bargains </title>
      </Helmet>

      <Container>
        {customLoader && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999999999999,
            }}
          >
            <CirclesWithBar
              height="100"
              width="100"
              color="#4fa94d"
              visible={customLoader}
              ariaLabel="circles-with-bar-loading"
            />
          </div>
        )}
      </Container>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            FCM Settings
          </Typography>
        </Stack>
        <Card style={{ padding: 15 }}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="row mt-3">
              <div className="col-lg-6">
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontSize: '14px', fontWeight: '400', marginLeft: '8px', marginBottom: '0px', color: '#000' }}
                >
                  Firebase/ Project ID
                </Typography>
                <TextField
                  fullWidth
                  // label="Firebase/ Project ID"
                  id="fiberBaseId"
                  disabled
                  name="fiberBaseId"
                  value={currentUser.fiberBaseId}
                  onChange={handleInputChanges}
                />
              </div>
              <div className="col-lg-6">{/* {} */}</div>
              <div className="col-lg-6 mt-3">
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontSize: '14px', fontWeight: '400', marginLeft: '8px', color: '#000' }}
                >
                  Config File Upload
                </Typography>
                <input type="file" className="file-int" onChange={handleFileChange} />
              </div>
            </div>

            <div style={{ padding: 15, margin: 5 }}>
              <Button variant="contained" onClick={updateUser} disabled={loadingSend}>
                {loadingSend ? <CircularProgress size={24} /> : 'Upload'}
              </Button>
            </div>
          </Box>
        </Card>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={4}>
          <Typography variant="h4" gutterBottom>
            Send Test Notification
          </Typography>
        </Stack>
        <Card style={{ padding: 20 }}>
          <Box component="form" noValidate autoComplete="off">
            <div className="row">
              <div className="col-lg-4 mt-3">
                <Autocomplete
                  disablePortal
                  options={currentUser}
                  getOptionLabel={(option) => option.fullName || ''} // Assuming the user object has a 'fullName' property
                  sx={{ width: 300 }}
                  onChange={handleUserSelect}
                  renderInput={(params) => <TextField {...params} label="User" />}
                />
              </div>
              <div className="col-lg-4 mt-3">
                <TextField
                  fullWidth
                  label="Device Id"
                  disabled
                  id="deviceToken"
                  name="deviceToken"
                  value={currentuserdevice.deviceToken}
                  onChange={handleInputChanges}
                />
              </div>
            </div>

            <div style={{ padding: 15, marginTop: '20px', marginBottom: '20px' }}>
              <Button variant="contained" onClick={noticationSend} disabled={loadingSend}>
                {loadingSend ? <CircularProgress size={24} /> : 'Send Notification'}
              </Button>
            </div>
          </Box>
        </Card>
      </Container>
    </>
  );
}
